import React, { Component } from "react";
import PropTypes from "prop-types";

class TextOnly extends Component {

    render(){
        const { title, column1, column2 } = this.props;
        return(
            <div className="content-page text">
                <div className="content-page-title">
                    <div className="content-page-title-decoration">—</div>
                    <h2>{ title }</h2>
                </div>
                <div className="content-page-cols">
                    <div className="content-page-col" dangerouslySetInnerHTML={ { __html: column1 }} />
                    <div className="content-page-col" dangerouslySetInnerHTML={ { __html: column2 }} />
                </div>
            </div>
        )
    }

}

TextOnly.propTypes = {
    title: PropTypes.string.isRequired,
    column1: PropTypes.string,
    column2: PropTypes.string
};

export default TextOnly;