import {
    GO_FORWARD,
    GO_BACK,
    GO_TO,
    TURN_ON_CONTENT_DELAY,
    TURN_OFF_CONTENT_DELAY,
    SET_NORMAL_BACK_BEHAVIOUR, SET_CONTENT_CLOSE_BACK_BEHAVIOUR
} from "../constants/action-types";
import {CONTENT_BACK_BEHAVIOUR, NORMAL_BACK_BEHAVIOUR} from "../constants/back-behaviours";

const navigationDefault = {
    level: 0,
    history: [ { id: 'grid-automation-service', name: 'Grid Automation Service' } ],
    delayContentEntrance: true,
    backBehaviour: NORMAL_BACK_BEHAVIOUR
};

export const navigation = ( state=navigationDefault, action ) => {
    switch( action.type ){
        case GO_FORWARD:
            return {
                level: state.level + 1,
                history: [ ...state.history, action.page ]
            };
        case GO_BACK:
            let newHistory = [ ...state.history ];
                newHistory.pop();
            return {
                level: state.level - 1,
                history: newHistory
            };
        case GO_TO:
            if( action.level === 0 ){
                return navigationDefault;
            }else {
                return {
                    level: action.level,
                    history: [...navigationDefault.history, action.page]
                };
            }
        case TURN_ON_CONTENT_DELAY:
            return {
                ...state,
                delayContentEntrance: true
            };
        case TURN_OFF_CONTENT_DELAY:
            return {
                ...state,
                delayContentEntrance: false
            };
        case SET_NORMAL_BACK_BEHAVIOUR:
            return {
                ...state,
                backBehaviour: NORMAL_BACK_BEHAVIOUR
            };
        case SET_CONTENT_CLOSE_BACK_BEHAVIOUR:
            return {
                ...state,
                backBehaviour: CONTENT_BACK_BEHAVIOUR
            };
        default:
            return state;
    }
};