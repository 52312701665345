import { MOVE_MAP_LEFT, MOVE_MAP_CENTRE } from "../constants/action-types";

const defaultPositions = {
    mapIsCentre: true
};

export const positions = ( state=defaultPositions, action ) => {
    switch( action.type ){
        case MOVE_MAP_LEFT:
            return {
                ...state,
                mapIsCentre: false
            };
        case MOVE_MAP_CENTRE:
            return {
                ...state,
                mapIsCentre: true
            };
        default:
            return state;
    }
};