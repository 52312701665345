import {GO_BACK, GO_FORWARD, GO_TO} from "../constants/action-types";

// Prevents duplicate history entries.
export const verifyHistory = store => next => action => {
    const navActions = [
      GO_TO,
      GO_FORWARD
    ];

    if( navActions.indexOf(action.type) !== -1 ){
        const { navigation: { history } } = store.getState();
        const mostRecentPage = [ ...history ].pop();

        if( mostRecentPage.id !== action.page.id ){
            return next(action);
        }
    }else{
        return next(action);
    }
};

// Prevents double back button presses.
export const verifyBack = store => next => action => {
    if( action.type === GO_BACK ){
        const { navigation: { history } } = store.getState();
        if( history.length >= 2 ){
            return next(action);
        }
    }else{
        return next(action);
    }
};