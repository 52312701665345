import React, { Component } from "react";
import classnames from "classnames";
import MapChildrenStar from "./map-star";
import MapChildrenTree from "./map-tree";

class MapPage extends Component {

    constructor(props){
        super(props);

        this.animationOutTime = 300;

        this.state = {
            title: props.title,
            mapChildren: props.mapChildren,
            rotation: parseInt(360 / props.mapChildren.length),
            isOpen: false,
            isTransitioning: false,
            firstLoaded: false,
            isEnd: props.isEnd,
            limitTitleLength: props.limitTitleLength
        };

        this.transitionCounter = null;

        this._onClick = this._onClick.bind(this);
    }

    componentWillUnmount() {
        clearTimeout( this.transitionCounter );
    }

    componentDidUpdate( prevProps ){
        const { title, mapChildren, limitTitleLength, isEnd } = this.props;
        if( title !== prevProps.title ){
            this.setState({
                isOpen: false,
                isTransitioning: true
            });
            this.transitionCounter = setTimeout(() => {
                this.setState({
                    title,
                    mapChildren,
                    rotation: parseInt(360 / mapChildren.length),
                    isOpen: true,
                    isTransitioning: false,
                    limitTitleLength,
                    isEnd
                });
            }, this.animationOutTime);
        }else if( title === prevProps.title && isEnd !== prevProps.isEnd ){
            this.setState({
                isEnd
            });
        }
    }

    _onClick() {
        const { isOpen } = this.state;
        if( !isOpen ) {
            this.setState({
                isOpen: true,
                firstLoaded: true
            });
        }
    }

    render() {
        const {
            title,
            mapChildren,
            rotation,
            isOpen,
            isTransitioning,
            isEnd,
            firstLoaded
        } = this.state;

        const pageClasses  = classnames(
            'map-page',
            { 'open-content' : isEnd }
        );

        const mainClasses = classnames(
            'map-main',
            { 'hoverable': !isOpen }
        );

        const titleClasses = classnames(
            'map-title',
            { 'transitioning': isTransitioning }
        );

        return (
           <div className={ pageClasses }>
               <div className="map-page-content">
                   <div className="map-main-background" />
                   <div className={ mainClasses } onClick={ this._onClick } onTouchStart={ this._onClick }>
                       <h1 className={ titleClasses }><div className="map-decoration">—</div><span dangerouslySetInnerHTML={{__html: title}} /></h1>
                   </div>
                   { (isEnd) ? (
                       <MapChildrenTree
                           isOpen={ isOpen }
                           children={ mapChildren }
                           isTransitioning={ isTransitioning }
                       />
                   ):(
                       <MapChildrenStar
                           isOpen={ isOpen }
                           isFirst={ firstLoaded }
                           titlesAreLimited={ true }
                           rotation={ rotation }
                           children={ mapChildren }
                       />
                   )}
               </div>
           </div>
        );
    }
}

export default MapPage;