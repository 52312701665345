import React, { Component } from "react";
import { connect } from "react-redux";
import BreadcrumbPresentational from "../presentational/breadcrumb";
import {clearActiveContent, goTo} from "../../actions";
import * as ReactGA from "react-ga";

class Breadcrumb extends Component {

    constructor(props){
        super(props);

        this._onClick = this._onClick.bind(this);
    }

    _onClick(depth,page) {
        const { goTo } = this.props;
        goTo(depth,page);
        ReactGA.event({
            category: "Navigation Interaction",
            action: "Breadcrumb Pressed"
        });
    }
    
    render() {
        const { history, activeContent, content } = this.props;
        let historyAndContent = [ ...history ];
        if( activeContent !== null ){
            historyAndContent.push({
               id: activeContent,
               name: content[activeContent].title
            });
        }
        return <BreadcrumbPresentational history={historyAndContent} onClick={ this._onClick }/>
    }
    
}

const mapStateToProps = ( state ) => {
    return {
        history: state.navigation.history,
        activeContent: state.activeContent,
        content: state.content
    }
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        goTo: (depth, page) => {
            dispatch( clearActiveContent() );
            dispatch( goTo(depth,page) );
        }
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(Breadcrumb);