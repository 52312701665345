import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import MapChild from "../functional/map-child";

class MapChildrenStar extends Component {

    render() {
        const {
            isOpen,
            isFirst,
            titlesAreLimited,
            rotation,
            children
        } = this.props;
        const childrenClasses = classnames(
            'map-children',
            { 'limit-title': titlesAreLimited },
            { 'entering': isOpen },
            { 'exiting': !isOpen && isFirst }
        );
        const hasEvenNumber = children.length % 2 === 0;

        return (
            <div key={ childrenClasses } className={ childrenClasses }>
                { children.map((child,key) => {
                    let thisRotation = rotation * key;
                    if( hasEvenNumber ){
                        thisRotation += 360 / (children.length * 2)
                    }
                    thisRotation = Math.round( thisRotation );
                    return <MapChild
                        key={ "child-"+key }
                        limitTitleLength={ titlesAreLimited }
                        child={child}
                        rotation={thisRotation}
                    />;
                })}
            </div>
        );
    }
}

MapChildrenStar.propTypes = {
    isOpen: PropTypes.bool,
    isFirst: PropTypes.bool,
    titlesAreLimited: PropTypes.bool,
    rotation: PropTypes.number,
    children: PropTypes.array
};

MapChildrenStar.defaultProps = {
    isOpen: false,
    isFirst: false,
    titlesAreLimited: false,
};

export default MapChildrenStar;