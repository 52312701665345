import { combineReducers } from 'redux';
import { structure } from "./structure";
import { navigation } from "./navigation";
import { content } from "./content";
import { activeContent } from "./active-content";
import { settings } from "./settings";
import { positions } from "./positions";

const allReducers = combineReducers({
    structure,
    navigation,
    content,
    activeContent,
    settings,
    positions
});

export default allReducers;
