import React, { Component } from "react";
import VideoContainerPresent from "../presentational/content-page-video-container";
import * as ReactGA from "react-ga";

class VideoContainer extends Component {

    constructor(props){
        super(props);

        this.state = {
            isFullScreen: false,
            videoIsPlaying: false
        };

        this.playTimer = null;
        this._onClick = this._onClick.bind(this);
        this._videoHasEnded = this._videoHasEnded.bind(this);
    }

    componentWillUnmount() {
        clearTimeout( this.playTimer );
    }

    _onClick() {
        const { video } = this.props;
        const { isFullScreen } = this.state;
        this.setState({
           isFullScreen: !isFullScreen
        });

        this.playTimer = setTimeout(() => {
            this.setState({
                videoIsPlaying: true
            });
        },285);

        ReactGA.event({
            category: "Content Interaction",
            action: "Video Played",
            label: video
        });
    }

    _videoHasEnded() {
        this.setState({
            isFullScreen: false,
            videoIsPlaying: false
        });
    }

    render() {
        const { video } = this.props;
        const { isFullScreen, videoIsPlaying } = this.state;

        return (
            <VideoContainerPresent
                video={ video }
                isFullScreen={ isFullScreen }
                isPlaying={ videoIsPlaying }
                onClick={ this._onClick }
                onEnd={ this._videoHasEnded }
            />
        );
    }

}

export default VideoContainer;