import React, { Component } from "react";
import { connect } from "react-redux";
import BackButtonPresentational from "../presentational/back-button";
import {clearActiveContent, goBack} from "../../actions";
import {NORMAL_BACK_BEHAVIOUR} from "../../constants/back-behaviours";
import * as ReactGA from "react-ga";

class BackButton extends Component {

    constructor(props){
        super(props);

        this.state = {
            isClicked: false,
            isVisible: false,
            isExiting: false
        };

        this.transitionTimer = null;
        this.clickTimer = null;

        this._onClick = this._onClick.bind(this);
        this._goBack = this._goBack.bind(this);
    }

    componentWillUnmount() {
        clearTimeout(this.transitionTimer);
        clearTimeout(this.clickTimer);
    }

    componentDidUpdate(prevProps) {
        const { level } = this.props;
        const { isVisible, isExiting } = this.state;

        if( level > 0 && !isVisible ){
            this.setState({
               isVisible: true
            });
        } else if( level === 0 && isVisible && !isExiting ){
            this.setState({
                isExiting: true
            });

            this.transitionTimer = setTimeout(() => {
                this.setState({
                   isVisible: false,
                   isExiting: false
                });
            } , 500);
        }
    }

    _onClick() {
        const { isClicked } = this.state;

        if( !isClicked ){
            this.setState({
                isClicked: true
            },() => {
                this._goBack();
            });

            this.clickTimer = setTimeout(() => {
                this.setState({
                   isClicked: false
                });
            },500);
        }
    }

    _goBack() {
        const { goBack, clearActiveContent, behaviour } = this.props;
        if( behaviour === NORMAL_BACK_BEHAVIOUR ){
            goBack();
        }else{
            clearActiveContent();
        }
        ReactGA.event({
            category: "Navigation Interaction",
            action: "Back Button Pressed"
        });
    }

    render() {
        const { isVisible, isExiting } = this.state;

        if( !isVisible ) {
            return null;
        }

        let label = ( this.props.history.length === 2 ) ? ( "Home" ):( "Menu" );

        let historyAndContent = [ ...this.props.history ];
        if( this.props.activeContent !== null ){
            historyAndContent.push({
                id: this.props.activeContent
            });

            if (this.props.behaviour === 'content')
                label = ( historyAndContent.length === 2 ) ? ( "Home" ):( "Menu" );
            else
                label = ( historyAndContent.length === 3 ) ? ( "Home" ):( "Menu" );
        }

        return (
            <BackButtonPresentational
                isExiting={ isExiting } onClick={ this._onClick }>
                { label }
            </BackButtonPresentational>
        );
    }

}

const mapStateToProps = ( state ) => {
    return {
        mapIsCentre: state.positions.mapIsCentre,
        level: state.navigation.level,
        history: state.navigation.history,
        activeContent: state.activeContent,
        behaviour: state.navigation.backBehaviour
    }
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        goBack: () => {
            dispatch( clearActiveContent() );
            dispatch( goBack() );
        },
        clearActiveContent: () => {
            dispatch( clearActiveContent() );
        }
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(BackButton);