import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { BREADCRUMB_EXIT } from "../../constants/animation-timings";

class Breadcrumb extends Component {

    constructor(props){
        super(props);

        this.state = {
            isTransitioning: false,
            history: props.history
        };

        this.transitionTimer = null;
    }

    componentWillUnmount() {
        clearTimeout(this.transitionTimer);
    }

    componentDidUpdate(prevProps) {
        const { history } = this.props;
        if( history.length < prevProps.history.length ){
            // History has changed trigger appropriate exits.
            this.setState({
                isTransitioning: true
            });
            this.transitionTimer = setTimeout(() => {
                this.setState({
                   isTransitioning: false,
                   history
                });
            },BREADCRUMB_EXIT);
        }else if( history[history.length-1].id !== prevProps.history[prevProps.history.length-1].id ){
            this.setState({
               history
            });
        }
    }

    render() {
        const { history, onClick } = this.props;
        const { isTransitioning } = this.state;
        const displayHistory = this.state.history;

        return (
            <div className="breadcrumb-container">
                <ul className="breadcrumb">
                    { displayHistory.map((item,index) => {
                        let itemClass = classnames(
                            { 'exiting' : isTransitioning && index > history.length - 1 }
                        );
                        return (
                            <li
                                key={"history-"+item.id+itemClass}
                                className={ itemClass }
                                onClick={ () => { onClick(index,item) } }
                                onTouchStart={ () => { onClick(index,item) } }
                                dangerouslySetInnerHTML={{__html: item.name}}
                            />
                        );
                    })}
                </ul>
            </div>
        );
    }


}

Breadcrumb.propTypes = {
    history: PropTypes.array.isRequired,
    onClick: PropTypes.func
};

export default Breadcrumb;