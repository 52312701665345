import { createStore, applyMiddleware } from "redux";
import allReducers from "./reducers";
import { verifyHistory, verifyBack } from "./middleware/navigation";
import { delayMapMovement } from "./middleware/animation-delays";

const preloadedState = {
    structure: window.structure,
    content: window.content,
    settings: window.settings
};

export const store = createStore(
    allReducers,
    preloadedState,
    applyMiddleware( verifyHistory, verifyBack, delayMapMovement )
);