import React, { Component } from "react";
import LogoImage from "../../assets/graphics/logo.svg";

class Logo extends Component {

    render() {
        return (
            <div className="logo-container">
                <img src={ LogoImage } alt="ABB" />
            </div>
        );
    }

}

export default Logo;