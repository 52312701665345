import React, { Component } from "react";
import { connect } from "react-redux";
import { getCurrentPage, pageHasGrandchildren } from "../../helpers/structure-helpers";
import MapPagePresentational from "../presentational/map-page";
import {
    turnOnContentDelay,
    turnOffContentDelay,
    moveMapLeft,
    moveMapCentre,
    setNormalBackBehaviour,
    setContentCloseBackBehaviour
} from "../../actions";
import * as ReactGA from "react-ga";

class MapPage extends Component {

    constructor(props){
        super(props);

        this.transitionTimer = null;
    }

    componentWillUnmount() {
        clearTimeout( this.transitionTimer );
    }

    componentDidUpdate() {
        const {
            history,
            turnOffContentDelay,
            turnOnContentDelay,
            activeContent,
            moveMapLeft,
            moveMapCentre
        } = this.props;
        const page = getCurrentPage( history );
        const hasGrandchildren = pageHasGrandchildren( page );
        turnOffContentDelay();
        if( hasGrandchildren && activeContent !== null ) {
            turnOnContentDelay();
            moveMapLeft();
        }else if( !hasGrandchildren || activeContent !== null ){
            moveMapLeft();
        }else{
            moveMapCentre();
        }

        ReactGA.pageview("/", null, page.name);
    }

    render() {
        const { history, activeContent, turnOnContentDelay, setNormalBackBehaviour, setContentBackBehaviour } = this.props;
        const page = getCurrentPage( history );
        let { name, children } = page;
        const hasGrandchildren = pageHasGrandchildren( page );
        const translateLeft = !hasGrandchildren || activeContent !== null;

        if( !hasGrandchildren ){
            // Definetly at a vertical branch at this stage.
            setNormalBackBehaviour();
        }else if( activeContent !== null ){
            // If we're not at a vertical branch but have content open. We'll only want to close the content.
            setContentBackBehaviour();
        }else {
            // Only thing left is interim branches. Act normally.
            setNormalBackBehaviour();
        }

        if( hasGrandchildren && activeContent !== null && children[activeContent] !== undefined ){
            turnOnContentDelay();
            name = children[activeContent].name;
            children = {
                "children": {
                    id: children[activeContent].id,
                    name: children[activeContent].name,
                    icon: children[activeContent].icon
                }
            }
        }

        const childrenArr = Object.values( children );

        return <MapPagePresentational
            title={ name }
            isFinalBranch={ !hasGrandchildren }
            isEnd={ translateLeft }
            mapChildren={ childrenArr }
            limitTitleLength={ history.length === 1 }
        />;
    }


}

const mapStateToProps = ( state ) => {
    return {
        level: state.navigation.level,
        history: state.navigation.history,
        content: state.content,
        activeContent: state.activeContent,
        mapIsCentre: state.positions.mapIsCentre
    }
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        turnOnContentDelay: () => {
            dispatch( turnOnContentDelay() );
        },
        turnOffContentDelay: () => {
            dispatch( turnOffContentDelay() );
        },
        moveMapLeft: () => {
            dispatch( moveMapLeft() );
        },
        moveMapCentre: () => {
            dispatch( moveMapCentre() );
        },
        setNormalBackBehaviour: () => {
            dispatch( setNormalBackBehaviour() );
        },
        setContentBackBehaviour: () => {
            dispatch( setContentCloseBackBehaviour() );
        }
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(MapPage);
