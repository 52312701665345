import { MOVE_MAP_LEFT, MOVE_MAP_CENTRE } from "../constants/action-types";
import { MAP_MOVEMENT_SPEED } from "../constants/animation-timings";

// Delays movement of positions based on animation time.
export const delayMapMovement = store => next => action => {
    const navActions = [
        MOVE_MAP_LEFT,
        MOVE_MAP_CENTRE
    ];
    const { positions: { mapIsCentre } } = store.getState();

    if( navActions.indexOf(action.type) !== -1 ){
        if( (action.type === MOVE_MAP_LEFT && mapIsCentre) ||
            (action.type === MOVE_MAP_CENTRE && !mapIsCentre) ){
            setTimeout(() => {
                return next(action);
            },MAP_MOVEMENT_SPEED);
        }else{
            return next(action);
        }
    }else{
        return next(action);
    }

};