import {
    GO_FORWARD,
    GO_BACK,
    GO_TO,
    SET_CONTENT,
    CLEAR_SET_CONTENT,
    TURN_ON_CONTENT_DELAY,
    TURN_OFF_CONTENT_DELAY, MOVE_MAP_LEFT, MOVE_MAP_CENTRE, SET_NORMAL_BACK_BEHAVIOUR, SET_CONTENT_CLOSE_BACK_BEHAVIOUR
} from "./constants/action-types";

export const goForward = ( page ) => {
    return {
        type: GO_FORWARD,
        page
    }
};

export const goBack = () => {
    return {
        type: GO_BACK
    }
};

export const goTo = ( level, page ) => {
    return {
        type: GO_TO,
        level,
        page
    }
};

export const setActiveContent = ( contentId ) => {
    return {
        type: SET_CONTENT,
        contentId
    }
};

export const clearActiveContent = () => {
    return {
        type: CLEAR_SET_CONTENT
    }
};

export const turnOnContentDelay = () => {
    return {
        type: TURN_ON_CONTENT_DELAY
    }
};

export const turnOffContentDelay = () => {
    return {
        type: TURN_OFF_CONTENT_DELAY
    }
};

export const moveMapLeft = () => {
    return {
        type: MOVE_MAP_LEFT
    }
};

export const moveMapCentre = () => {
    return {
        type: MOVE_MAP_CENTRE
    }
};

export const setNormalBackBehaviour = () => {
    return {
        type: SET_NORMAL_BACK_BEHAVIOUR
    }
};

export const setContentCloseBackBehaviour = () => {
    return {
        type: SET_CONTENT_CLOSE_BACK_BEHAVIOUR
    }
};