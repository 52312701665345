import React, { Component } from "react";
import { connect } from "react-redux";
import MapPage from "./components/functional/map-page";
import Breadcrumb from "./components/functional/breadcrumb";
import BackButton from "./components/functional/back-button";
import ContentPage from "./components/functional/content-page";
import Logo from "./components/presentational/logo";
import * as ReactGA from "react-ga";
import IdleTimer from "react-idle-timer";

class Main extends Component {

    constructor(props){
        super(props);
        this.isTouching = false;

        this._onIdle = this._onIdle.bind(this);
        this._startTouch = this._startTouch.bind(this);
        this._endTouch = this._endTouch.bind(this);
    }

    componentDidMount() {
        const { trackingId } = this.props;
        ReactGA.initialize( trackingId );
    }

    _startTouch(event) {
        if( this.isTouching ){
            event.preventDefault();
        }else {
            this.isTouching = true;
        }
    }

    _endTouch(){
        this.isTouching = false;
    }


    _onIdle() {
        const { level } = this.props;
        if( level !== 0 ) {
            // Don't refresh if on the first screen.
            ReactGA.event({
                category: "App State",
                action: "Idled"
            });
            window.location.reload();
        }
    }

    render() {
        const { idleTimeout } = this.props;

        return (
                <div
                    className="page-container"
                    onTouchStart={ this._startTouch }
                    onTouchEnd={ this._endTouch }
                >
                    <Breadcrumb/>
                    <BackButton/>
                    <MapPage/>
                    <ContentPage/>
                    <Logo />
                    <IdleTimer
                        onIdle={ this._onIdle }
                        timeout={ idleTimeout }
                    />
                </div>
            );
    }

}

const mapStateToProps = ( state ) => {
    return {
        trackingId: state.settings.gaTrackingId,
        idleTimeout: state.settings.idleTimeout,
        level: state.navigation.level
    };
};

export default connect(mapStateToProps)(Main);
