import React, { Component } from "react";
import classnames from "classnames";
import MapTreeChild from "../functional/map-tree-child";

class MapChildrenTree extends Component {

    constructor(props){
        super(props);

        this.state = {
            linesAreAnimating: false
        };

        this.lineCountdown = null;
    }

    componentWillUnmount() {
        clearTimeout( this.lineCountdown );
    }

    componentDidMount() {
        this.lineCountdown = setTimeout(() => {
            this.setState({
                linesAreAnimating: true
            });
        },200);
    }

    render() {
        const {children, isTransitioning} = this.props;
        const {linesAreAnimating} = this.state;
        const childrenCount = children.length;
        const totalAnimationTime = ((children.length/2 - 1) * 150) + 400;

        const mainClasses = classnames(
            'map-children-tree-wrap',
            { 'exiting': isTransitioning }
        );

        const topClasses = classnames(
            'map-children-scaffold-cover',
            'top',
            { 'animate': linesAreAnimating }
        );

        const bottomClasses = classnames(
            'map-children-scaffold-cover',
            'bottom',
            { 'animate': linesAreAnimating }
        );

        const treeClasses = classnames(
            'map-children-tree-list',
            { 'exiting' : isTransitioning }
        );

        return (
            <div className={ mainClasses }>
                <div className="map-children-tree">
                    <div className="map-children-scaffold">
                        {(childrenCount > 1) ? (
                            <div className="map-children-scaffold-canvas" key={ isTransitioning ? 'scaffold-transition' : 'scaffold' }>
                                <div className="map-children-scaffold-main-wrap">
                                    <div className="map-children-scaffold-main" />
                                </div>
                                <div className="map-children-scaffold-cover-middle"/>
                                <div className={ topClasses }/>
                                <div className={ bottomClasses }/>
                                <div className="map-children-scaffold-indicator"/>
                            </div>
                        ):(
                            <div className="map-children-scaffold-line-container" key={ isTransitioning ? 'scaffold-transition' : 'scaffold' }>
                                <div className="map-children-scaffold-line" />
                            </div>
                        )}
                    </div>
                    <div className={ treeClasses }>
                        {children.map((child, index) => {
                            const midPoint = (childrenCount + 1) / 2;
                            const distanceFromMiddle = Math.abs(midPoint - (index + 1));
                            const animationDelay = 400 + 150 * Math.round(distanceFromMiddle);

                            return <MapTreeChild
                                key={child.id + "-tree-child"}
                                id={child.id}
                                title={child.name}
                                icon={child.icon}
                                delay={animationDelay}
                                index={index}
                                totalAnimationTime={totalAnimationTime}
                            />
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default MapChildrenTree;