import React, { Component } from "react";
import PropTypes from "prop-types";

class Image extends Component {

    render() {
        const {
            title,
            column1,
            column2,
            image
        } = this.props;
        return (
            <div className="content-page image">
                <div className="content-page-media-container">
                    <div className="content-page-media-image">
                        { image !== "image" && <img src={ image } alt={ title } /> }
                        { image === "image" && <span className="image-placeholder" /> }
                    </div>
                </div>
                <div className="content-page-title">
                    <div className="content-page-title-decoration">—</div>
                    <h2>{ title }</h2>
                </div>
                <div className="content-page-cols">
                    <div className="content-page-col" dangerouslySetInnerHTML={ { __html: column1 }} />
                    <div className="content-page-col" dangerouslySetInnerHTML={ { __html: column2 }} />
                </div>
            </div>
        );
    }

}

Image.propTypes = {
    title: PropTypes.string.isRequired,
    column1: PropTypes.string,
    column2: PropTypes.string,
    image: PropTypes.string
};

export default Image;