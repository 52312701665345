import React, { Component } from "react";
import { connect } from "react-redux";
import { setActiveContent } from "../../actions";
import MapTreeChildPresent from "../presentational/map-tree-child";

class MapTreeChild extends Component {

    constructor(props){
        super(props);

        this.state = {
            textVisible: false
        };

        this.firstRevealTimer = null;
        this.textRevealTimer = null;

        this._setAsActive = this._setAsActive.bind(this);
    }

    componentDidMount() {
        const { index, totalAnimationTime } = this.props;
        // If this child is the first, we'll set it to be active after the entrance animation has completed.
        if( index === 0 ) {
            this.firstRevealTimer = setTimeout(() => {
                const { activeContent } = this.props;
                if( activeContent === null ) {
                    // Don't set if the user has already clicked an option.
                    this._setAsActive();
                }
            }, totalAnimationTime + 1250);
        }

        this.textRevealTimer = setTimeout(() => {
            this.setState({
               textVisible: true
            });
        },totalAnimationTime);
    }

    componentWillUnmount() {
        clearTimeout( this.firstRevealTimer );
        clearTimeout( this.textRevealTimer );
    }

    _setAsActive() {
        const { id, setActiveContent } = this.props;
        setActiveContent(id);
    }

    render() {
        const { activeContent, id, title, icon, delay } = this.props;
        const { textVisible } = this.state;
        const isActive = activeContent === id;
        const isFaded = activeContent !== null && activeContent !== id;

        return (
            <MapTreeChildPresent
                title={ title }
                icon={ icon }
                delay={ delay }
                isActive={ isActive }
                isFaded={ isFaded }
                textVisible={ textVisible }
                onClick={ this._setAsActive }
            />
        );
    }

}

const mapStateToProps = ( state ) => {
    return {
        activeContent: state.activeContent
    }
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        setActiveContent: ( contentId ) => {
            dispatch( setActiveContent(contentId) );
        }
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(MapTreeChild);

