import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { MAP_MOVEMENT_SPEED, CHILD_REVEALED } from "../../constants/animation-timings";

class MapChild extends Component {

    render() {
        const {
            title,
            icon,
            rotation,
            onClick,
            delayEntrance
        } = this.props;

        const allStyles = {
            transform: "rotate(" + rotation + "deg)",
            animationDelay: delayEntrance ? MAP_MOVEMENT_SPEED + 'ms' : 0
        };

        const contentStyles = {
            transform: "rotate(" + (360 - rotation) + "deg)",
            animationDelay: delayEntrance ? MAP_MOVEMENT_SPEED + 'ms' : 0
        };

        const dividerStyles = {
            animationDelay: delayEntrance ? MAP_MOVEMENT_SPEED + 'ms' : 0
        };

        const nameStyles = {
            animationDelay: delayEntrance ? MAP_MOVEMENT_SPEED + CHILD_REVEALED + rotation/360 * 3000 + 'ms' : rotation/360 * 3000 + 'ms'
        };

        const titleLocation = classnames(
            'map-child-name',
            { 'left': rotation > 180 && rotation < 360 },
            { 'top': rotation === 0|| rotation === 360 }
        );

        return (
            <div className="map-child-container" onClick={ onClick } onTouchStart={ onClick } style={ allStyles }>
                <div className="map-child-content-container" style={ contentStyles }>
                    <div className="map-child-icon">
                        { icon && <img src={ icon } alt={ title } /> }
                    </div>
                    <div className={ titleLocation } style={nameStyles}>
                        <span dangerouslySetInnerHTML={{__html: title}} />
                    </div>
                </div>
                <div className="map-child-divider" style={ dividerStyles } />
            </div>
        );
    }

}

MapChild.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string,
    rotation: (props,propName,componentName) => {
        if( typeof props[propName] !== "number" ){
            return new Error('Invalid type of `' + propName + '` supplied to `' + componentName );
        }else if( props[propName] < 0 || props[propName] > 360 ){
            return new Error(props[propName] + ' must be in the range 0-360 in ' + componentName);
        }
    },
    onClick: PropTypes.func,
    delayEntrance: PropTypes.bool
};

MapChild.defaultProps = {
    rotation: 0,
    delayEntrance: false
};

export default MapChild;