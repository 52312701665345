import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

class MapTreeChild extends Component {

    render() {
        const {
            title,
            icon,
            onClick,
            delay,
            isActive,
            isFaded,
            textVisible
        }= this.props;

        const delayStyle = {
            animationDelay: delay+'ms'
        };

        const childClasses = classnames(
            "map-tree-child",
            { "active": isActive },
            { "faded": isFaded }
        );

        const textClasses = classnames(
            "map-tree-child-name",
            { "visible": textVisible }
        );

        return (
            <div className={ childClasses } onClick={ onClick } onTouchStart={ onClick }>
                <div className="map-tree-child-icon" style={ delayStyle }>
                    { icon && <img src={ icon } alt={ title } /> }
                </div>
                <div className={ textClasses } dangerouslySetInnerHTML={{__html: title}} />
            </div>
        );
    }

}

MapTreeChild.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    delay: PropTypes.number,
    isActive: PropTypes.bool,
    isFaded: PropTypes.bool,
    textVisible: PropTypes.bool
};

MapTreeChild.defaultProps = {
    isActive: false,
    isFaded: false,
    textVisible: false
};

export default MapTreeChild;
