import { store } from '../store';

// Returns the current last page of history.
export const getCurrentPage = ( history ) => {
    const { structure } = store.getState();
    let currentBranch = structure['grid-automation-service'];

    if( history.length === 1 ){
        return currentBranch;
    }

    for(let i=0; i<history.length; i++){
        if( currentBranch.children[history[i].id] !== undefined ) {
            currentBranch = currentBranch.children[history[i].id];
        }
    }

    return currentBranch;
};

export const currentPageHasChildren = ( history ) => {
    const { children } = getCurrentPage( history );
    return children !== undefined;
};

export const pageHasGrandchildren = ( page ) => {
    const { children } = page;
    const childrenArr = Object.values( children );
    for(let i=0; i<childrenArr.length;i++) {
        if( childrenArr[i].children !== undefined ){
            return true;
        }
    }
    return false;
};

export const getHistoryObject = ( page ) => {
    return {
        id: page.id,
        name: page.name
    }
};