import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import CloseButton from "../presentational/close-button";
import VideoPoster from "../../assets/images/video-placeholder.jpg";

class VideoContainer extends Component {

    constructor(props){
        super(props);

        this.video = React.createRef();
        this.container = React.createRef();

        this.offsetRight = 0;
        this.offsetBottom = 0;

        this._onClick = this._onClick.bind(this);
        this._onEnd = this._onEnd.bind(this);
    }

    componentDidUpdate( prevProps ) {
        const { isPlaying } = this.props;
        if( !prevProps.isPlaying && isPlaying ) {
            this.video.current.play();
        }
    }

    _onClick() {
        const { onClick } = this.props;
        const { current } = this.container;
        const boundingBox = current.getBoundingClientRect();
        this.offsetRight = window.innerWidth - boundingBox.right;
        this.offsetBottom = window.innerHeight - boundingBox.bottom;
        onClick();
    }

    _onEnd() {
        const { onEnd } = this.props;
        this.video.current.currentTime = 0;
        onEnd();
    }

    render() {
        const { video, isFullScreen } = this.props;
        const containerClasses = classnames(
            "content-page-media-video",
            { "full-screen": isFullScreen }
        );

        const videoStyles = {
            right: isFullScreen ? this.offsetRight*-1 : 0,
            bottom: isFullScreen ? this.offsetBottom*-1 : 0
        };

        return(
            <div className={ containerClasses } ref={ this.container } onClick={ this._onClick }>
                <div className="content-page-media-video-backdrop" style={ videoStyles } />
                <div className="content-page-media-video-wrap" style={ videoStyles }>
                    <div className="content-page-media-video-centre">
                        <div className="content-page-media-video-ratio">
                            <video
                                ref={ this.video }
                                poster={ VideoPoster }
                                onEnded={ this._onEnd }
                                preload="auto"
                            >
                                <source src={ video } type="video/mp4" />
                            </video>
                            { isFullScreen && <CloseButton/> }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

VideoContainer.propTypes = {
    video: PropTypes.string,
    isFullScreen: PropTypes.bool,
    isPlaying: PropTypes.bool,
    onClick: PropTypes.func,
    onEnd: PropTypes.func
};

VideoContainer.defaultProps = {
    isPlaying: false,
    isFullScreen: false
};

export default VideoContainer;