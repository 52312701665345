import React, { Component } from "react";
import VideoPresent from "../presentational/content-page-video";

class Video extends Component {

    render() {
        const {
            title,
            column1,
            column2,
            video
        } = this.props;

        return <VideoPresent
            title={title}
            column1={column1}
            column2={column2}
            video={video}
        />
    }
}

export default Video;