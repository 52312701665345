// REDUCER ACTIONS

// ACTIVE CONTENT
export const SET_CONTENT = "SET_CONTENT";
export const CLEAR_SET_CONTENT = "CLEAR_SET_CONTENT";

// NAVIGATION
export const GO_FORWARD = "GO_FORWARD";
export const GO_BACK = "GO_BACK";
export const GO_TO = "GO_TO";
export const TURN_ON_CONTENT_DELAY = "TURN_ON_CONTENT_DELAY";
export const TURN_OFF_CONTENT_DELAY = "TURN_OFF_CONTENT_DELAY";
export const SET_NORMAL_BACK_BEHAVIOUR = "SET_NORMAL_BACK_BEHAVIOUR";
export const SET_CONTENT_CLOSE_BACK_BEHAVIOUR = "SET_CONTENT_CLOSE_BACK_BEHAVIOUR";

// POSITIONS
export const MOVE_MAP_LEFT = "MOVE_MAP_LEFT";
export const MOVE_MAP_CENTRE = "MOVE_MAP_CENTRE";