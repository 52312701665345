import { SET_CONTENT, CLEAR_SET_CONTENT } from "../constants/action-types";

export const activeContent = ( state=null, action ) => {
    switch(action.type){
        case SET_CONTENT:
            return action.contentId;
        case CLEAR_SET_CONTENT:
            return null;
        default:
            return state;
    }
};