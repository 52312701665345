import React, { Component } from 'react';
import './styles/style.css';
import { Provider } from "react-redux";
import { store } from "./store";
import Main from "./Main";
import 'babel-polyfill';

class App extends Component {
  render() {
    return (
        <Provider store={ store }>
            <div className="app-container">
              <Main/>
            </div>
        </Provider>
    );
  }
}


export default App;
