import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import TextOnly from "./content-page-text-only";
import Image from "./content-page-image";
import Video from "../functional/content-page-video";
import {MAP_MOVEMENT_SPEED,CHILD_REVEALED} from "../../constants/animation-timings";

class ContentPage extends Component {

    constructor(props){
        super(props);

        this.state = {
           isVisible: !props.delayed
        };

        this.delayCountdown = null;
    }

    componentWillUnmount() {
        clearTimeout(this.delayCountdown);
    }

    componentDidMount() {
        const { delayed } = this.props;
        if( delayed ){
            this.delayCountdown = setTimeout(() => {
               this.setState({
                  isVisible: true
               });
            },MAP_MOVEMENT_SPEED + CHILD_REVEALED);
        }
    }

    render() {
        const {
            title,
            column1,
            column2,
            image,
            video,
            poster,
            template,
            isExiting
        } = this.props;
        const { isVisible } = this.state;

        const pageClasses = classnames(
            "content-page-container",
            { "exiting": isExiting }
        );

        return (
            <div key={ pageClasses } className={ pageClasses }>
                {(() => {
                    if( !isVisible ){
                        return null;
                    }else {
                        switch (template) {
                            case "image":
                                return <Image title={title} column1={column1} column2={column2} image={image}/>
                            case "video":
                                return <Video title={title} column1={column1} column2={column2} video={video}
                                              poster={poster}/>;
                            default:
                                return <TextOnly title={title} column1={column1} column2={column2}/>;
                        }
                    }
                })()}
            </div>
        );
    }
}

ContentPage.propTypes = {
    title: PropTypes.string.isRequired,
    column1: PropTypes.string,
    column2: PropTypes.string,
    image: PropTypes.string,
    video: PropTypes.string,
    poster: PropTypes.string,
    template: PropTypes.oneOf(['text-only','image','video']),
    isExiting: PropTypes.bool,
    delayed: PropTypes.bool
};

ContentPage.defaultProps = {
    isExiting: false,
    delayed: false
};

export default ContentPage;