import React, { Component } from "react";
import { connect } from "react-redux";
import ContentPagePresent from "../presentational/content-page";
import * as ReactGA from "react-ga";

class ContentPage extends Component {

    constructor(props){
        super(props);

        this.state = {
            title: props.title,
            column1: props.column1,
            column2: props.column2,
            image: props.image,
            video: props.video,
            poster: props.poster,
            template: props.template,
            isExiting: false
        };

        this.transitionTimer = null;
    }

    componentWillUnmount() {
        clearTimeout(this.transitionTimer);
    }

    componentDidUpdate( prevProps ){
        const {
            isActive,
            title,
            column1,
            column2,
            image,
            video,
            poster,
            template
        } = this.props;
        if( prevProps.isActive && !isActive ) {
            this.setState({
                isExiting: true
            }, () => {
                this.transitionTimer = setTimeout(() => {
                    this.setState({
                        title,
                        column1,
                        column2,
                        image,
                        video,
                        poster,
                        template
                    });
                }, 500);
            });
        }else if( prevProps.isActive && isActive && title !== prevProps.title ){
            this.setState({
                isExiting: true
            }, () => {
                this.transitionTimer = setTimeout(() => {
                    this.setState({
                        title,
                        column1,
                        column2,
                        image,
                        video,
                        poster,
                        template,
                        isExiting: false
                    });
                }, 500);
            });
        }else if( title !== prevProps.title ){
            this.setState({
                title,
                column1,
                column2,
                image,
                video,
                poster,
                template,
                isExiting: false
            });
        }

        if( title !== prevProps.title ) {
            ReactGA.pageview("/", null, title);
        }
    }

    render() {
        const {
            title,
            column1,
            column2,
            image,
            video,
            poster,
            template,
            isExiting
        } = this.state;
        const { entranceIsDelayed } = this.props;

        if( title === null ) {
            return null;
        }

        return (
            <ContentPagePresent
                title={ title }
                column1={ column1 }
                column2={ column2 }
                image={ image }
                video={ video }
                poster={ poster }
                template={ template }
                isExiting={ isExiting }
                delayed={ entranceIsDelayed }
            />
        )
    }

}

const mapStateToProps = ( state ) => {
    const { activeContent } = state;
    const isActive = activeContent !== null;
    if( isActive ) {
        const {
            title,
            column1,
            column2,
            image,
            video,
            poster,
            template
        } = state.content[activeContent];
        return {
            isActive,
            title,
            column1,
            column2,
            image,
            video,
            poster,
            template,
            entranceIsDelayed: state.navigation.delayContentEntrance
        }
    }else{
        return {
            isActive,
            title: null,
            column1: null,
            column2: null,
            image: null,
            video: null,
            poster: null,
            template: null,
            entranceIsDelayed: state.navigation.delayContentEntrance
        }
    }
};

export default connect(mapStateToProps)(ContentPage);