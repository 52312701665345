import React, { Component } from "react";
import { connect } from "react-redux";
import { goForward, setActiveContent } from "../../actions";
import MapChildPresentational from "../presentational/map-child";
import { getHistoryObject } from "../../helpers/structure-helpers";

class MapChild extends Component {
    constructor(props){
        super(props);

        this._onClick = this._onClick.bind(this);
    }

    _onClick() {
        const { goForward, child, setActiveContent } = this.props;

        if( child.children !== undefined ) {
            const historyObj = getHistoryObject(child);
            goForward(historyObj);
        }else{
            setActiveContent(child.id);
        }
    }

    render() {
        const { rotation, child, mapIsCentre } = this.props;

        return (
            <MapChildPresentational
                title={ child.name }
                icon={ child.icon }
                rotation={ rotation }
                onClick={ this._onClick }
                delayEntrance={ !mapIsCentre }
            />);
    }

}

const mapStateToProps = ( state ) => {
    return {
        mapIsCentre: state.positions.mapIsCentre
    }
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        goForward: ( page ) => {
            dispatch( goForward(page) );
        },
        setActiveContent: (id) => {
            dispatch( setActiveContent(id) );
        }
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(MapChild);