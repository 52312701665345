import React, { Component } from "react";
import PropTypes from "prop-types";
import VideoContainer from "../functional/content-page-video-container";

class Video extends Component {

    render() {
        const {
            title,
            column1,
            column2,
            video
        } = this.props;
        return (
            <div className="content-page video">
                <div className="content-page-title">
                    <div className="content-page-title-decoration">—</div>
                    <h2>{ title }</h2>
                </div>
                <div className="content-page-cols">
                    <div className="content-page-col" dangerouslySetInnerHTML={{ __html: column1 }} />
                    <div className="content-page-col" dangerouslySetInnerHTML={{ __html: column2 }} />
                </div>
                <div className="content-page-media-container">
                    <VideoContainer
                        video={ video }
                    />
                </div>
            </div>
        );
    }

}

Video.propTypes = {
    title: PropTypes.string.isRequired,
    column1: PropTypes.string,
    column2: PropTypes.string,
    video: PropTypes.string,
    onPlay: PropTypes.func
};

export default Video;