import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

class BackButton extends Component {

    constructor(props){
        super(props);

        this.state = {
            text: props.children,
            isTransitioning: false
        };

        this.transitionTimer = null;
    }

    componentWillUnmount() {
        clearTimeout( this.transitionTimer );
    }

    componentDidUpdate( prevProps ) {
        const { children } = this.props;

        if( children !== prevProps.children ){
            this.setState({
               isTransitioning: true
            });
            this.transitionTimer = setTimeout(() => {
                this.setState({
                    text: children,
                    isTransitioning: false
                });
            }, 500);
        }
    }


    render() {
        const { onClick,isExiting } = this.props;
        const { text, isTransitioning } = this.state;
        const classes = classnames(
            "back-button",
            { "exiting": isExiting }
        );
        const textClasses = classnames(
            { "transitioning": isTransitioning }
        );

        return (
            <div className="back-button-container">
                <button
                    key={ classes }
                    className={ classes }
                    onClick={ onClick }
                    onTouchStart={ onClick }
                >
                    <span className={textClasses}>{ text }</span>
                </button>
            </div>
        );
    }

}

BackButton.propTypes = {
    onClick: PropTypes.func,
    isExiting: PropTypes.bool
};

BackButton.defaultProps = {
    isExiting: false
};

export default BackButton;