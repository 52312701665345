import React, { Component } from "react";
import PropTypes from "prop-types";

class CloseButton extends Component{

    render() {
        const { onClose } = this.props;

        return(
            <button className="video-overlay-close" onClick={ onClose }/>
        );
    }

}

CloseButton.propTypes = {
    onClose: PropTypes.func
};

export default CloseButton;